import { DateTime } from 'luxon';

/**
 * Returns the date from UTC timezone in the format YYYY-MM-DD
 */
export function utcToIso8601Date(date: Date): string {
	return date.toISOString().split('T')[0];
}

/**
 * Returns the date from local timezone in the format YYYY-MM-DD
 */
export function iso8601Date(date: Date): string {
	const dateParts = date
		.toLocaleDateString('en-US', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		})
		.split('/');
	return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
}

export function dateFromIso8601DateForDatePicker(date?: string): Date {
	if (date) {
		// Return the date starting at midnight of the current timezone (don't include Z)
		return new Date(`${date}T00:00:00`);
	}
	return new Date();
}

/**
 * Returns the date in a normal, locale-aware format.
 * E.g. In en-us this will be "January 12, 2022"
 * We are setting timeZone to UTC because using local timezones lead to off by one date errors
 */
export function formatDate(date: Date): string {
	return date.toLocaleDateString(undefined, {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
		timeZone: 'UTC',
	});
}

export type DayAbbreviation = 'M' | 'T' | 'W' | 'R' | 'F' | 'S' | 'U';
const dayAbbreviation = (abbreviation: DayAbbreviation): string => {
	switch (abbreviation) {
		default:
		case 'M':
			return 'Monday';
		case 'T':
			return 'Tuesday';
		case 'W':
			return 'Wednesday';
		case 'R':
			return 'Thursday';
		case 'F':
			return 'Friday';
		case 'S':
			return 'Saturday';
		case 'U':
			return 'Sunday';
	}
};

/**
 * Converts short day abbreviations to actual days of the week
 * @param dates Days of the week
 * @returns human-readable dates as ex. Monday, Tuesday, and Thursday
 */
export function convertDayAbbreviationToFull(days: DayAbbreviation[]): string {
	if (days.length === 0) {
		return '';
	}

	const fullDays = days.map(dayAbbreviation);
	if (fullDays.length === 1) {
		return fullDays[0];
	}
	if (fullDays.length === 2) {
		return `${fullDays[0]} and ${fullDays[1]}`;
	}
	const lastDay = fullDays.pop();
	return `${fullDays.join(', ')}, and ${lastDay}`;
}

/**
 * Returns the date in the format MM/DD/YY
 */
export function formatShortDate(date: Date): string {
	return date.toLocaleDateString(undefined, {
		month: 'numeric',
		day: 'numeric',
		year: '2-digit',
	});
}

/**
 * Returns a boolean to determine if the string date parameter has passed
 * E.g. if today is July 26, 2022 and the formProps are `"2021-03-16"` - true would be returned
 */

export const hasDatePassed = (date: string): boolean => {
	return new Date(date) < new Date();
};

/**
 * Returns a formatted dollar amount
 */
export function currencyStringFromDollars(dollars?: number, currency = 'USD'): string {
	const language = typeof window !== 'undefined' ? window?.navigator?.language : undefined;
	if (Number.isNaN(dollars)) {
		return '-';
	}
	const options = { style: 'currency', currency };
	const numberFormat = new Intl.NumberFormat(language, options);

	return numberFormat.format(dollars ?? 0);
}

/**
 * Returns a formatted dollar amount
 */
export function currencyStringFromCents(cents?: number, currency = 'USD'): string {
	return currencyStringFromDollars(cents ? cents / 100 : cents, currency);
}

/**
 * Performs string validation
 */
export function validateString(type: string, value: string): boolean {
	if (typeof value !== 'string') {
		return false;
	}
	switch (type) {
		case 'email':
			return (
				Boolean(value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i)) &&
				value.length < 100
			);
		default:
			return false;
	}
}

/**
 * Returns a boolean that let's you know if a Pathway can be edited or not
 * If emails are turned on, use announcement date, otherwise use start date
 */
export function isEditable(shouldShowEmails: boolean, startDate: Date, announceDate?: Date): boolean {
	const dateToParse = shouldShowEmails && announceDate ? announceDate : startDate;
	const twoHoursBeforeDate = DateTime.fromJSDate(new Date(dateToParse)).minus({ hours: 2 });
	return twoHoursBeforeDate >= DateTime.now();
}

export function addCommasToNumber(number: number): string {
	return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(number);
}
