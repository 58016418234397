import { useElements, useStripe, CardElement, PaymentElement } from '@stripe/react-stripe-js';
import { CreateTokenCardData, StripeCardElement, StripeCardNumberElement, Token } from '@stripe/stripe-js';
import { useCallback } from 'react';

type GetStripeToken = (opts: { name: string | undefined }) => Promise<Token | undefined>;

interface CreateStripeToken {
	({
		cardInfo,
		data,
	}: {
		cardInfo: StripeCardElement | StripeCardNumberElement;
		data: CreateTokenCardData;
	}): Promise<Token | undefined>;
}

interface GetCardType {
	// eslint-disable-next-line @rushstack/no-new-null
	(): StripeCardElement | null | undefined;
}

type GetPaymentMethodId = () => Promise<string | undefined>;

type UseStripeHelp = () => {
	getCardElement: GetCardType;
	getStripeToken: GetStripeToken;
	getPaymentMethodId: GetPaymentMethodId;
	createStripeToken: CreateStripeToken;
};

export const useStripeHelp: UseStripeHelp = () => {
	const stripe = useStripe();
	const elements = useElements();

	const getCardElement: GetCardType = useCallback(() => {
		return elements?.getElement(CardElement);
	}, [elements]);

	const createStripeToken: CreateStripeToken = useCallback(
		async ({ cardInfo, data }) => {
			if (!stripe || !cardInfo) {
				throw new Error(`missing_card_info`);
			}
			const { error, token } = await stripe.createToken(cardInfo, data);
			if (error || !token) throw new Error(error?.code);
			return token;
		},
		[stripe],
	);

	const getStripeToken: GetStripeToken = useCallback(
		async ({ name }) => {
			const cardElement = getCardElement();

			if (cardElement) {
				return createStripeToken({
					cardInfo: cardElement,
					data: { name },
				});
			}
		},
		[createStripeToken, getCardElement],
	);

	const getPaymentMethodId: GetPaymentMethodId = useCallback(async () => {
		if (!stripe || !elements) {
			return;
		}
		const paymentElement = elements.getElement(PaymentElement);
		if (!paymentElement) return;

		await elements.submit();
		const { paymentMethod } = await stripe.createPaymentMethod({
			elements,
		});
		return paymentMethod?.id;
	}, [elements, stripe]);

	return {
		getStripeToken,
		getPaymentMethodId,
		getCardElement,
		createStripeToken,
	};
};
